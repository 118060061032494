@font-face {
  font-family: 'ChulaCharasNew';
  src: local('ChulaCharasNew'), url('/fonts/ChulaCharasNewReg.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ChulaCharasNew';
  src: local('ChulaCharasNew Bold'), url('/fonts/ChulaCharasNewBold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ChulaCharasNew';
  src: local('ChulaCharasNew Italic'), url('/fonts/ChulaCharasNewIta.ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ChulaCharasNew';
  src: local('ChulaCharasNew Bold Italic'), url('/fonts/ChulaCharasNewBoldIta.ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: inherit;
}
